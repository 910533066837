<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'facturacion_servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : table_data.length > 0
                  ? `(${table_data.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-select
            label="Selecciona una opción..."
            v-model="filter"
            :items="filters"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="loading"
            @change="getTableData"
          />
        </v-col>
        <v-col cols="12" xs="12" md="6" v-if="table_data.length > 0">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="loading"
            dense
          >
            <template v-slot:item.counter="{ item }">
              <span class="font-weight-bold" v-text="item.counter" />
            </template>

            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    v-on="on"
                    class="ml-1"
                    :color="item.date_bank_check ? 'warning' : 'info'"
                    @click.prevent="paymentsDetail(item.id)"
                  >
                    <v-icon> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    fab
                    x-small
                    dark
                    @click.prevent="paymentGroup(item.group)"
                  >
                    <v-icon> mdi-book-multiple </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Detalle de lote'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog
      v-model="payments_dialog"
      scrollable
      persistent
      max-width="1800px"
    >
      <v-card
        tile
        :disabled="payments_dialog_loading"
        :loading="payments_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> DETALLE </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payments_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="payments_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>H. Médicos</th>
                              <th>Cobro aseg.</th>
                              <th>Total a pagar</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                pay_insurance, i
                              ) in payments_data.pay_insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_insurance.name" />
                              <td>
                                {{
                                  numberFormat(pay_insurance.medical_fees) +
                                  " (" +
                                  pay_insurance.quantity_medical_fees +
                                  ")"
                                }}
                              </td>
                              <td>
                                {{
                                  numberFormat(
                                    pay_insurance.bill_payment_total
                                  ) +
                                  " (" +
                                  pay_insurance.quantity +
                                  ")"
                                }}
                              </td>
                              <td
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      fab
                                      x-small
                                      color="teal darken-3"
                                      dark
                                      @click.prevent="
                                        payInsuranceDialog(pay_insurance)
                                      "
                                    >
                                      <v-icon> mdi-group </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="'Concentradores | Contratantes'"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />

                              <td class="font-weight-bold">
                                <!-- {{ numberFormat(0) + " (" + "000" + ")" }} -->
                              </td>
                              <td class="font-weight-bold">
                                <!-- {{
                                  numberFormat(
                                    payments_data.pay_insurances_bill_payment_total
                                  ) +
                                  " (" +
                                  "000" +
                                  ")"
                                }} -->
                                {{
                                  numberFormat(
                                    payments_data.pay_insurances_bill_payment_total
                                  )
                                }}
                              </td>
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payments_data.pay_insurances_payment_total
                                  )
                                "
                              />
                              <td />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ORDEN DE PAGO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-card class="mx-auto">
                        <v-card-title>
                          <h2
                            class="text-caption"
                            v-text="
                              `${payments_data.folio} - ${
                                payments_data.provider_brand_id
                                  ? `${
                                      payments_data.provider_brand
                                        .provider_brand
                                    } | ${
                                      payments_data.provider_brand.bank.bank
                                    } | ${
                                      payments_data.format == 1
                                        ? `Cuenta ${payments_data.provider_brand.account_number}`
                                        : `CLABE ${payments_data.provider_brand.clabe}`
                                    }`
                                  : `SIN CONCENTRADOR (${
                                      payments_data.format == 1
                                        ? 'BBVA'
                                        : 'OTROS'
                                    })`
                              }`
                            "
                          />
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12">
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Folio</th>
                                      <th>Tipo</th>
                                      <th>Aseguradora</th>
                                      <th>Doc. ID</th>
                                      <th>Contratante</th>
                                      <th>Concentrador</th>
                                      <th>Registro</th>
                                      <th>Recepción</th>
                                      <th>Promesa pago</th>
                                      <th>Banco</th>
                                      <th>Beneficiario</th>
                                      <th
                                        v-if="!payments_data.provider_brand_id"
                                      >
                                        {{
                                          payments_data.format == 1
                                            ? "Cuenta"
                                            : "CLABE"
                                        }}
                                      </th>
                                      <th>Cobro aseg.</th>
                                      <th>Total a pagar</th>
                                      <th>Monto a pagar</th>
                                      <th>F. pago</th>
                                      <th>Monto pagado</th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, j) in payments_data.items"
                                      :key="j"
                                      :class="
                                        item.remove ? 'red lighten-5' : ''
                                      "
                                    >
                                      <td v-text="j + 1" />
                                      <td v-text="item.full_folio" />
                                      <td v-text="item.rs_type" />
                                      <td
                                        v-text="
                                          item.rs_service_bill.rs_service.rs
                                            .insurance.name
                                        "
                                      />
                                      <td v-text="item.rs_service_bill.folio" />
                                      <td
                                        v-text="
                                          item.rs_service_bill.rs_service.rs
                                            .contractor.name
                                        "
                                      />
                                      <td
                                        v-text="
                                          item.rs_service_bill.rs_service.rs
                                            .contractor.contractor_brand
                                            ? item.rs_service_bill.rs_service.rs
                                                .contractor.contractor_brand
                                                .contractor_brand
                                            : ''
                                        "
                                      />
                                      <td
                                        v-text="item.rs_service_bill.created_at"
                                      />
                                      <td
                                        v-text="
                                          item.rs_service_bill.reception_date
                                        "
                                      />
                                      <td
                                        v-text="item.rs_service_bill.pay_date"
                                      />
                                      <td>
                                        <!-- <div> -->
                                        {{
                                          item.rs_service_bill.rs_service.bank
                                            ? item.rs_service_bill.rs_service
                                                .bank.bank
                                            : "NO REGSITRADO"
                                        }}
                                        <!-- </div>
                                        <div>
                                          <small>{{ item.id }}</small>
                                        </div> -->
                                      </td>
                                      <td v-text="item.beneficiary" />
                                      <td v-text="item.beneficiary_account" />
                                      <td
                                        v-text="
                                          numberFormat(
                                            item.rs_service_bill
                                              .insurance_pay_amount
                                          )
                                        "
                                      />
                                      <td
                                        v-text="
                                          numberFormat(
                                            item.rs_service_bill.pay_amount
                                          )
                                        "
                                      />
                                      <td v-text="numberFormat(item.amount)" />
                                      <td
                                        v-text="
                                          item.paid_date ? item.paid_date : '-'
                                        "
                                      />
                                      <td
                                        v-text="
                                          item.paid_amount
                                            ? numberFormat(item.paid_amount)
                                            : '-'
                                        "
                                      />
                                      <td>
                                        <v-icon
                                          v-if="
                                            item.verify != null &&
                                            item.verify == true
                                          "
                                          small
                                          color="success"
                                        >
                                          mdi-check
                                        </v-icon>
                                        <v-tooltip
                                          v-if="
                                            item.verify != null &&
                                            item.verify == false &&
                                            !item.remove
                                          "
                                          left
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              class="ml-1"
                                              v-on="on"
                                              fab
                                              x-small
                                              color="error"
                                              dark
                                              @click.prevent="paymentRemove(j)"
                                            >
                                              <v-icon> mdi-alert </v-icon>
                                            </v-btn>
                                          </template>
                                          <span
                                            v-text="'Eliminar de esta orden'"
                                          />
                                        </v-tooltip>
                                        <!-- <v-tooltip v-if="!item.paid_date" left> -->
                                        <v-tooltip v-if="false" left>
                                          <template v-slot:activator="{ on }">
                                            <v-btn
                                              class="ml-1"
                                              v-on="on"
                                              fab
                                              x-small
                                              color="warning "
                                              dark
                                            >
                                              <v-icon> mdi-pencil </v-icon>
                                            </v-btn>
                                          </template>
                                          <span v-text="'Editar'" />
                                        </v-tooltip>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td />
                                      <td
                                        class="font-weight-bold"
                                        v-text="
                                          numberFormat(
                                            payments_data.pay_providers_bill_payment_total
                                          )
                                        "
                                      />
                                      <td
                                        class="font-weight-bold"
                                        v-text="
                                          numberFormat(
                                            payments_data.pay_providers_payment_total
                                          )
                                        "
                                      />
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="3"
                              v-if="!payments_data.date_bank_check"
                            >
                              <v-btn
                                v-if="payments_data.format == 1"
                                color="cyan darken-2"
                                class="mr-1"
                                dark
                                small
                                @click.prevent="
                                  paymentsGenerateTxt(
                                    payments_data.id,
                                    payments_data.folio,
                                    1
                                  )
                                "
                              >
                                BBVA
                              </v-btn>
                              <v-btn
                                v-if="payments_data.format == 1"
                                color="pink darken-2"
                                class="mr-1"
                                dark
                                small
                                @click.prevent="
                                  paymentsGenerateTxt(
                                    payments_data.id,
                                    payments_data.folio,
                                    3
                                  )
                                "
                              >
                                CIE
                              </v-btn>
                              <v-btn
                                v-if="payments_data.format == 2"
                                color="teal darken-3"
                                dark
                                small
                                @click.prevent="
                                  paymentsGenerateTxt(
                                    payments_data.id,
                                    payments_data.folio,
                                    2
                                  )
                                "
                              >
                                Interbancario
                              </v-btn>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="9"
                              v-if="
                                !payments_data.date_bank_check &&
                                !payments_receipt_file
                              "
                            >
                              <v-row dense>
                                <v-col cols="5">
                                  <v-file-input
                                    v-model="
                                      payments_receipt.document_receipt_0
                                    "
                                    accept=".xlsx"
                                    label="Comprobante de pago"
                                    dense
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    label="Lectura formato"
                                    v-model="payments_receipt.format"
                                    :items="formats"
                                    item-value="id"
                                    :item-text="(v) => v.name"
                                    dense
                                  />
                                </v-col>
                                <v-col cols="3">
                                  <v-btn
                                    block
                                    color="warning"
                                    small
                                    @click.prevent="paymentsReceiptAnalyze"
                                    :disabled="
                                      !payments_receipt.document_receipt_0 ||
                                      payments_dialog_loading
                                    "
                                    :loading="payments_dialog_loading"
                                  >
                                    Cargar comprobante
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="9"
                              class="text-right"
                              v-if="
                                !payments_data.bank_check &&
                                payments_receipt_file
                              "
                            >
                              <v-btn
                                class="mr-1"
                                small
                                @click.prevent="paymentsReceiptAnalyzeDefault"
                              >
                                Cargar otro comprobante
                              </v-btn>
                              <v-btn
                                small
                                color="success"
                                :disabled="!payments_verifieds"
                                @click.prevent="paymentsReceipt"
                              >
                                Guardar comprobante
                              </v-btn>
                            </v-col>
                            <v-col
                              cols="12"
                              class="text-right"
                              v-if="payments_data.date_bank_check"
                            >
                              <v-btn
                                color="info"
                                dark
                                small
                                :href="
                                  url_documents +
                                  '/rs_service_bill_payments/' +
                                  payments_data.url_receipt
                                "
                                target="_blank"
                              >
                                Ver comprobante de pago
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payment_group_dialog"
      scrollable
      persistent
      max-width="2000px"
    >
      <v-card
        tile
        v-if="payment_group_dialog"
        :loading="payment_group_dialog_loading"
        :disabled="payment_group_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            Lote {{ payment_group_data ? payment_group_data.group : "" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="payment_group_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="payment_group_data">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ASEGURADORAS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                pay_insurance, i
                              ) in payment_group_data.pay_insurances"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_insurance.name" />
                              <td v-text="pay_insurance.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      fab
                                      x-small
                                      color="teal darken-3"
                                      dark
                                      @click.prevent="
                                        insurancePayDetail(pay_insurance)
                                      "
                                    >
                                      <v-icon> mdi-group </v-icon>
                                    </v-btn>
                                  </template>
                                  <span
                                    v-text="'Concentradores | Contratantes'"
                                  />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_insurances_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_insurances_bill_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_insurances_letters_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'HOSPITALES'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                pay_provider, i
                              ) in payment_group_data.pay_providers"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="pay_provider.name" />
                              <td v-text="pay_provider.quantity" />
                              <td
                                v-text="
                                  numberFormat(pay_provider.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(pay_provider.bill_payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_providers_payment_total
                                  )
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data.pay_providers_bill_payment_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-for="(
                rs_service_bill_payment, i
              ) in payment_group_data.rs_service_bill_payments"
              :key="i"
            >
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="
                      `${
                        rs_service_bill_payment.provider_brand_id
                          ? `${rs_service_bill_payment.folio} | ${
                              rs_service_bill_payment.provider_brand
                                .provider_brand
                            } | ${
                              rs_service_bill_payment.provider_brand.bank.bank
                            } | ${
                              rs_service_bill_payment.format == 1
                                ? `Cuenta ${rs_service_bill_payment.provider_brand.account_number}`
                                : `CLABE ${rs_service_bill_payment.provider_brand.clabe}`
                            }`
                          : `SIN CONCENTRADOR (${
                              rs_service_bill_payment.format == 1
                                ? 'BBVA'
                                : 'OTROS'
                            })`
                      }`
                    "
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Folio</th>
                              <th>Aseguradora</th>
                              <th>Médico / Proveedor</th>
                              <th>Contratante</th>
                              <th>Doc. ID</th>
                              <th
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id
                                "
                              >
                                Banco
                              </th>
                              <th
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 1
                                "
                              >
                                Cuenta
                              </th>
                              <th
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 2
                                "
                              >
                                CLABE
                              </th>
                              <th>F. registro</th>
                              <th>Monto factura SM</th>
                              <th>F. recepción</th>
                              <th>F. promesa de pago</th>
                              <th>Monto a pagar</th>
                              <th>F. pago</th>
                              <th>Monto pagado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                payment, j
                              ) in rs_service_bill_payment.items"
                              :key="j"
                            >
                              <td v-text="j + 1" />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service.rs.folio
                                "
                              />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service.rs
                                    .insurance.name
                                "
                              />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service
                                    .provider_name
                                "
                              />
                              <td
                                v-text="
                                  payment.rs_service_bill.rs_service.rs
                                    .contractor
                                    ? payment.rs_service_bill.rs_service.rs
                                        .contractor.name
                                    : '-'
                                "
                              />
                              <td v-text="payment.rs_service_bill_document" />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id
                                "
                                v-text="
                                  payment.rs_service_bill.rs_service.bank.bank
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 1
                                "
                                v-text="
                                  payment.rs_service_bill.rs_service
                                    .account_number
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 2
                                "
                                v-text="
                                  payment.rs_service_bill.rs_service.clabe
                                "
                              />
                              <td v-text="payment.created_at" />
                              <td
                                v-text="
                                  numberFormat(
                                    payment.rs_service_bill.insurance_pay_amount
                                  )
                                "
                              />
                              <td
                                v-text="payment.rs_service_bill.reception_date"
                              />
                              <td v-text="payment.rs_service_bill.pay_date" />
                              <td v-text="numberFormat(payment.amount)" />
                              <td
                                v-text="
                                  payment.paid_date ? payment.paid_date : '-'
                                "
                              />
                              <td
                                v-text="
                                  payment.paid_amount
                                    ? numberFormat(payment.paid_amount)
                                    : '-'
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 1
                                "
                              />
                              <td
                                v-if="
                                  !rs_service_bill_payment.provider_brand_id &&
                                  rs_service_bill_payment.format == 2
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data
                                      .rs_service_bill_payment_amounts[i]
                                      .bill_payment_total
                                  )
                                "
                              />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data
                                      .rs_service_bill_payment_amounts[i]
                                      .payment_total
                                  )
                                "
                              />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    payment_group_data
                                      .rs_service_bill_payment_amounts[i]
                                      .paid_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_payments_dialog"
      scrollable
      persistent
      max-width="1400px"
    >
      <v-card
        tile
        v-if="insurance_payments_data"
        :loading="insurance_payments_dialog_loading"
        :disabled="insurance_payments_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="insurance_payments_data.name" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_payments_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="'CONCENTRADORES | CONTRATANTES'"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>H. Médicos</th>
                              <th>Cobro aseg.</th>
                              <th>Total a pagar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                contractor_brand, i
                              ) in insurance_payments_data.contractor_brands"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="contractor_brand.name" />
                              <td>
                                {{
                                  numberFormat(contractor_brand.medical_fees) +
                                  " (" +
                                  contractor_brand.quantity_medical_fees +
                                  ")"
                                }}
                              </td>
                              <td>
                                {{
                                  numberFormat(
                                    contractor_brand.bill_payment_total
                                  ) +
                                  " (" +
                                  contractor_brand.quantity +
                                  ")"
                                }}
                              </td>
                              <td
                                v-text="
                                  numberFormat(contractor_brand.payment_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td class="font-weight-bold">
                                {{
                                  numberFormat(
                                    insurance_payments_data.medical_fees
                                  ) +
                                  " (" +
                                  insurance_payments_data.quantity_medical_fees +
                                  ")"
                                }}
                              </td>
                              <td class="font-weight-bold">
                                {{
                                  numberFormat(
                                    insurance_payments_data.bill_payment_total
                                  ) +
                                  " (" +
                                  insurance_payments_data.quantity +
                                  ")"
                                }}
                              </td>
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(
                                    insurance_payments_data.payment_total
                                  )
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="pay_insurance_dlg"
      scrollable
      persistent
      max-width="1400px"
    >
      <v-card
        tile
        v-if="pay_insurance"
        :loading="pay_insurance_dlg_ldg"
        :disabled="pay_insurance_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="pay_insurance.name" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="pay_insurance_dlg = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2
                    class="text-caption"
                    v-text="'CONCENTRADORES | CONTRATANTES'"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Ingresos</th>
                              <th>Monto pago total</th>
                              <th>Monto factura SM total</th>
                              <th>Honorarios Médicos</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                contractor_brand, i
                              ) in pay_insurance.contractor_brands"
                              :key="i"
                            >
                              <td v-text="i + 1" />
                              <td v-text="contractor_brand.name" />
                              <td v-text="contractor_brand.quantity" />
                              <td
                                v-text="
                                  numberFormat(contractor_brand.payment_total)
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(
                                    contractor_brand.bill_payment_total
                                  )
                                "
                              />
                              <td
                                v-text="
                                  numberFormat(contractor_brand.letters_total)
                                "
                              />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.bill_payment_total)
                                "
                              />
                              <td
                                class="font-weight-bold"
                                v-text="
                                  numberFormat(pay_insurance.letters_total)
                                "
                              />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  msgConfirm,
  rules,
  toFormData,
  URL_DOCUMENTS,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: false,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: true, value: "counter" },
        { text: "Folio", filterable: true, value: "folio" },
        {
          text: "Concentrador",
          filterable: true,
          value: "provider_brand_name",
        },
        { text: "Fecha", filterable: true, value: "created_at" },
        { text: "Lote", filterable: true, value: "group" },
        { text: "Monto", filterable: true, value: "amount" },
        { text: "", value: "action", sortable: false },
      ],
      filter: 2,
      filters: [
        { id: 2, value: "PENDIENTES" },
        { id: 3, value: "CARGADOS" },
        { id: 1, value: "TODOS" },
      ],
      filter_insurance: [],
      filter_insurances: [],
      rules: rules(),
      payments_data: null,
      payments_dialog: null,
      payments_dialog_loading: null,
      insurance_payments_data: null,
      insurance_payments_dialog: null,
      insurance_payments_dialog_loading: null,
      payments_receipt: {
        rs_service_bill_payment_items: null,
        document_receipt_0: null,
        format: null,
      },
      formats: [
        {
          id: 1,
          name: "BBVA",
        },
        {
          id: 3,
          name: "BBVA CIE",
        },
        {
          id: 2,
          name: "INTERBANCARIO",
        },
      ],
      payments_receipt_file: false,
      payments_verifieds: null,
      url_documents: URL_DOCUMENTS,
      payments_remove: 0,
      pay_verifieds_total: 0,
      payment_group_data: null,
      payment_group_dialog: false,
      payment_group_dialog_loading: false,
      pay_insurance: null,
      pay_insurance_dlg_ldg: false,
      pay_insurance_dlg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.table_data = [];

      Axios.get(
        `${URL_API}/rss/services/bills/payments?filter=${this.filter}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.table_data = resp.data.data;
        this.loading = false;
      });
    },
    paymentsDetail(id) {
      this.payments_data = null;
      this.payments_dialog_loading = true;
      this.payments_dialog = true;
      this.payments_receipt.document_receipt_0 = null;
      this.payments_receipt.format = null;
      this.payments_receipt_file = false;
      this.payments_verified = null;

      Axios.get(
        `${URL_API}/rss/services/bills/payments/${id}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.payments_data = resp.data.data;
        this.payments_receipt.format = this.payments_data.format;

        this.payments_dialog_loading = false;
      });
    },
    paymentGroup(group) {
      this.payment_group_dialog = true;
      this.payment_group_data = null;
      this.payment_group_dialog_loading = true;

      Axios.get(
        `${URL_API}/rss/services/bills/payments/group/${group}`,
        headersToken(this.login.token)
      ).then((response) => {
        this.payment_group_data = response.data.data;
        this.payment_group_data.group = group;
        this.payment_group_dialog_loading = false;
      });
    },
    paymentsGenerateTxt(id, folio, format) {
      this.payments_dialog_loading = true;

      Axios.get(
        URL_API + `/rss/services/bills/payments/${id}/file/${format}`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        if (resp.data.success) {
          this.payments_dialog_loading = false;

          const linkSource = `data:application/txt;base64,${resp.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `SMS_${folio}_${resp.data.now}_${resp.data.format}.txt`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          console.log(resp.data.message);
        }
      });
    },
    payInsuranceDialog(data) {
      this.insurance_payments_data = null;
      this.insurance_payments_dialog_loading = true;
      this.insurance_payments_dialog = true;
      this.insurance_payments_data = data;
      this.insurance_payments_dialog_loading = false;
    },
    paymentsReceiptAnalyze() {
      this.payments_dialog_loading = true;
      this.payments_receipt_file = false;
      this.payments_remove = 0;
      this.payments_verifieds = null;
      this.pay_verifieds_total = 0;
      this.payments_receipt.rs_service_bill_payment_items =
        this.payments_data.items;
      this.payments_receipt.rs_service_bill_payment_id = this.payments_data.id;

      Axios.post(
        `${URL_API}/rss/services/bills/payments/receipt/analyze`,
        toFormData(this.payments_receipt),
        headersToken(this.login.token)
      ).then((resp) => {
        this.$swal.fire(
          msgAlert(resp.data.success ? "success" : "error", resp.data.message)
        );

        if (resp.data.success) {
          this.payments_data.items = resp.data.data;
          this.payments_receipt.rs_service_bill_payment_items = resp.data.data;
          this.payments_verifieds = resp.data.pay_verifieds;
          this.pay_verifieds_total = resp.data.pay_verifieds_total;
          this.payments_receipt_file = true;
        } else {
          console.log(resp.data.message);
        }

        this.payments_dialog_loading = false;
      });
    },
    paymentsReceiptAnalyzeDefault() {
      this.payments_receipt_file = false;
      this.payments_verifieds = null;

      for (let item of this.payments_data.items) {
        item.paid_date = null;
        item.paid_amount = null;
        item.verify = null;
      }
    },
    paymentsReceipt() {
      this.$swal
        .fire(
          msgConfirm(`¿Confirma guardar comprobante para la orden de pago?`)
        )
        .then((resp) => {
          if (resp.isConfirmed) {
            this.payments_dialog_loading = true;

            Axios.post(
              `${URL_API}/rss/services/bills/payments/receipt`,
              toFormData(this.payments_receipt),
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.payments_dialog = false;
                this.payments_dialog_loading = false;
                this.getTableData();
              } else {
                console.log(resp.data.message);
              }
            });
          }
        });
    },
    paymentRemove(index) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar pago de orden?`))
        .then((resp) => {
          if (resp.isConfirmed) {
            this.payments_data.items[index].remove = true;
            this.payments_remove++;

            const total_items = this.payments_remove + this.pay_verifieds_total;

            if (total_items == this.payments_data.items.length) {
              this.payments_verifieds = true;
            }
          }
        });
    },
    insurancePayDetail(pay_insurance) {
      this.pay_insurance = null;
      this.pay_insurance_dlg_ldg = true;
      this.pay_insurance_dlg = true;
      this.pay_insurance = pay_insurance;
      this.pay_insurance_dlg_ldg = false;
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getTableData();
  },
};
</script>